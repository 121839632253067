import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_product_card = _resolveComponent("product-card")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_range = _resolveComponent("ion-range")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('ProductPage.newReview')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { fullscreen: true }, {
      default: _withCtx(() => [
        _createVNode(_component_product_card, {
          product: _ctx.product,
          hideDetails: "true"
        }, null, 8, ["product"]),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, { position: "stacked" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('ProductPage.reviewRating')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_range, {
              min: "0",
              max: "5",
              step: "1",
              snaps: "true",
              color: "warning",
              modelValue: _ctx.rating,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.rating) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { slot: "start" }, {
                  default: _withCtx(() => [
                    _createTextVNode("0")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_label, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createTextVNode("5")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, { position: "stacked" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('ProductPage.reviewContent')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_textarea, {
              modelValue: _ctx.content,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.content) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              expand: "block",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.createNewProductReview(_ctx.product.id, _ctx.rating, _ctx.content))),
              disabled: _ctx.rating =='' || _ctx.content=='' 
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('ProductPage.publishReview')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}