
// swiper
import 'swiper/swiper.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import 'swiper/modules/navigation/navigation.min.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Pagination, Autoplay, Navigation, } from 'swiper';

// icons
import { image, eye } from 'ionicons/icons';

// components
import { IonicSlides, IonList, IonListHeader, IonImg, IonText, IonIcon, modalController, } from '@ionic/vue';
import ImageModal from '@/components/modals/ImageModal.vue';

export default {
  props: [
    "defaultCaption",
    "listHeader",
    "imageLinks",
    "photos",
    "minSlidesPerView",
    "size",
    "hideEyeIcon",
  ],
  components: { Swiper, SwiperSlide, IonList, IonListHeader, IonImg, IonText, IonIcon, },
  setup(props: any) {
    const minSlidesPerView = props.minSlidesPerView || 1;
    const ops = {
      slidesPerView: Math.max(minSlidesPerView, 1),
      breakpoints: {
        768: { slidesPerView: Math.max(minSlidesPerView, 3), },
        1024: { slidesPerView: Math.max(minSlidesPerView, 4), }
      },
    };
    const openImageModal = async (imageLink: any, caption: any) => {
      const modal = await modalController.create({
        component: ImageModal,
        componentProps: { imageLink, caption },
      });
      return modal.present();
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      image, eye,

      // variables
      ops,

      // methods
      openImageModal,

      // swiper modules
      modules: [IonicSlides, Navigation, Pagination, Autoplay],
    }
  }
}
